import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


export default function ViewPyLogDetails() {
    const { id } = useParams();  // Get the optional client ID from the route


    const [clients, setClients] = useState([]);


    useEffect(() => {
        fetch(`/py-log-details/${id}`)
            .then(response => response.json())
            .then(resp => {
                const filteredClients = resp?.users;
                setClients(filteredClients);
                
            })
            .catch(error => console.error('Error fetching client data:', error));
    }, [id]);

    const LogViewer = ({ data }) => (
        <div>
            <h2>Python Script Log data</h2>
           
                <div key={data._id} className="user-section">
                   
                    <div className="logs">
                        {data?.logs?.map(log => (
                            <div key={log._id} className="log-entry">
                                <p><strong>Timestamp:</strong> {new Date(log.timestamp).toLocaleString()}</p>
                               
                                <p><strong>Message:</strong> {log.message}</p>
                            </div>
                        ))}
                    </div>
                </div>
          
        </div>
    );
  return (
    <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
                <div className="min-height-200px">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="title">
                                    <h4>View Python LLM Logs</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-box mb-30">
                        <div className="pd-20">
                        </div>
                        <div className="pb-20">
                          
                          <LogViewer data={clients} />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
