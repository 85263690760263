import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: null,
    user: null,
  });
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const token = Cookies.get('token');
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;

    if (token) {
      setAuthState({ token, user });
    }
    setLoading(false); // Set loading to false after initializing
  }, []);

  const login = (token, userData) => {
    Cookies.set('token', token);
    Cookies.set('user', JSON.stringify(userData));
    setAuthState({ token, user: userData });
  };

  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('user');
    setAuthState({ token: null, user: null });
  };

  const clientverify=(client_url,status)=>{

    Cookies.set(client_url, status);


  }

  return (
    <AuthContext.Provider value={{ authState, login, logout, loading,clientverify }}>
      {children}
    </AuthContext.Provider>
  );
};
