import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function MainImageLogo() {
    const [imgSrc, setImgSrc] = useState(''); // State to store the image source
    const { client_url } = useParams();

    useEffect(() => {
        const getImgLogo = async () => {
          try {
            const defaultLang = localStorage.getItem('default_lang') || 'en';
    
            const response = await fetch(`/clientimage/${defaultLang}/${client_url}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            setImgSrc(data.img); // Update the state with the image source
          } catch (error) {
            console.error('Error:', error);
            // Handle the error if needed
          }
        };
    
        getImgLogo(); // Call the function when the component mounts
      }, []); // Empty dependency array means this effect runs once after the initial render
    
      if(imgSrc){
        return <div className="videoWrap"><img src={imgSrc} id="imagelogo" alt="Logo" /></div>;

      }

}

export default MainImageLogo