import React from 'react'
import Header from './Header'
import RightSidebar from './RightSidebar'
import Leftbar from './Leftbar'
import ViewClientTable from './ViewClientTable'
export default function ViewClient() {
  return (
    <div>
          {/* <Header />
        <RightSidebar />
        <Leftbar /> */}
        <ViewClientTable />
    </div>
    
   
  )
}
