import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { useDispatch } from 'react-redux';
import { hideComponents } from '../store';

const ProtectedRoute = ({ element: Component,fallbackElement: FallbackComponent, roles, ...rest}) => {
  const { authState, loading } = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
      // Hide header and sidebars when this route is active
      if (authState.token && roles && !roles.includes(authState.user?.role)) {
      dispatch(hideComponents({ showHeader: false, showSidebar: false }));

      }else{
        dispatch(hideComponents({ showHeader: true, showSidebar: true }));
      }
      //dispatch(hideComponents({ showHeader: true, showSidebar: true }));
      // If you need to reset visibility when component unmounts
      // return () => {
      //     dispatch(hideComponents({ showHeader: true, showSidebar: true }));
      // };
  }, [dispatch,authState]);

  if (loading) {
    return <div>Loading...</div>; // Or a spinner/loading component
  }

  if (!authState.token) {
    return <Navigate to="/login" />;
  }

  // if (!loading && roles && !roles.includes(authState?.user.role)) {
  //   return <Navigate to="/not-authorized" />; // Redirect to a "not authorized" page or any other page
  // }


  if (roles && roles.includes(authState.user?.role)) {
    if (authState.user.role === 1) {
      // Admin role, render the ViewClient component
      return <Component {...rest} />;
    } else {
      // Other roles, render the fallback component (BackendForm)
      return FallbackComponent ? <FallbackComponent {...rest} /> : <Component {...rest} />;
    }
  } else {
    return <Navigate to="/not-authorized" />;
  }



};

export default ProtectedRoute;
