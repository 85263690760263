import React, { useState, useEffect } from 'react';

export default function LanguageHeader() {
  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const existingScript = document.querySelector(`script[src="${src}"]`);
      if (existingScript) {
        console.log(`Script ${src} already loaded`);
        return resolve();
      }

      const script = document.createElement('script');
      script.src = src;
      script.async = false;
      script.onload = () => {
        console.log(`Script ${src} loaded successfully`);
        resolve();
      };
      script.onerror = (error) => {
        console.error(`Failed to load script ${src}`, error);
        reject(error);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    const loadAllScripts = async () => {
      try {
        await loadScript('/select2.js');
      } catch (error) {
        console.error('Failed to load one or more scripts', error);
      }
    };

    loadAllScripts();
  }, []);
  useEffect(() => {
    // Call your initialization function here if it exists
    if (typeof window.yourScriptInitFunction === 'function') {
      window.yourScriptInitFunction();
    }
  }, []);
  return (
    <form>
    <div className="form-group mb-0">
        <label for="main_language">Language:</label>
        <select id="main_language" name="tl" className="form-control" style={{ width: '50%' }}>
            <option value="af">Afrikaans</option>
            <option value="sq">Albanian</option>
            <option value="am">Amharic</option>
            <option value="ar">Arabic</option>
            <option value="hy">Armenian</option>
            <option value="az">Azerbaijani</option>
            <option value="bjs">Bajan</option>
            <option value="rm">Balkan Gipsy</option>
            <option value="eu">Basque</option>
            <option value="bem">Bemba</option>
            <option value="bn">Bengali</option>
            <option value="be">Bielarus</option>
            <option value="bi">Bislama</option>
            <option value="bs">Bosnian</option>
            <option value="br">Breton</option>
            <option value="bg">Bulgarian</option>
            <option value="my">Burmese</option>
            <option value="ca">Catalan</option>
            <option value="ceb">Cebuano</option>
            <option value="ch">Chamorro</option>
            <option value="zh">Chinese (Simplified)</option>
            <option value="zh">Chinese Traditional</option>
            <option value="zdj">Comorian (Ngazidja)</option>
            <option value="cop">Coptic</option>
            <option value="aig">Creole English (Antigua and Barbuda)</option>
            <option value="bah">Creole English (Bahamas)</option>
            <option value="gcl">Creole English (Grenadian)</option>
            <option value="gyn">Creole English (Guyanese)</option>
            <option value="jam">Creole English (Jamaican)</option>
            <option value="svc">Creole English (Vincentian)</option>
            <option value="vic">Creole English (Virgin Islands)</option>
            <option value="ht">Creole French (Haitian)</option>
            <option value="acf">Creole French (Saint Lucian)</option>
            <option value="crs">Creole French (Seselwa)</option>
            <option value="pov">Creole Portuguese (Upper Guinea)</option>
            <option value="hr">Croatian</option>
            <option value="cs">Czech</option>
            <option value="da">Danish</option>
            <option value="nl">Dutch</option>
            <option value="dz">Dzongkha</option>
            <option value="en">English</option>
            <option value="eo">Esperanto</option>
            <option value="et">Estonian</option>
            <option value="fn">Fanagalo</option>
            <option value="fo">Faroese</option>
            <option value="fi">Finnish</option>
            <option value="fr">French</option>
            <option value="gl">Galician</option>
            <option value="ka">Georgian</option>
            <option value="de">German</option>
            <option value="el">Greek</option>
            <option value="grc">Greek (classNameical)</option>
            <option value="gu">Gujarati</option>
            <option value="ha">Hausa</option>
            <option value="haw">Hawaiian</option>
            <option value="he">Hebrew</option>
            <option value="hi">Hindi</option>
            <option value="hu">Hungarian</option>
            <option value="is">Icelandic</option>
            <option value="id">Indonesian</option>
            <option value="kl">Inuktitut (Greenlandic)</option>
            <option value="ga">Irish Gaelic</option>
            <option value="it">Italian</option>
            <option value="ja">Japanese</option>
            <option value="jv">Javanese</option>
            <option value="kea">Kabuverdianu</option>
            <option value="kab">Kabylian</option>
            <option value="kn">Kannada</option>
            <option value="kk">Kazakh</option>
            <option value="km">Khmer</option>
            <option value="rw">Kinyarwanda</option>
            <option value="rn">Kirundi</option>
            <option value="ko">Korean</option>
            <option value="ku">Kurdish</option>
            <option value="ckb">Kurdish Sorani</option>
            <option value="ky">Kyrgyz</option>
            <option value="lo">Lao</option>
            <option value="la">Latin</option>
            <option value="lv">Latvian</option>
            <option value="lt">Lithuanian</option>
            <option value="lb">Luxembourgish</option>
            <option value="mk">Macedonian</option>
            <option value="mg">Malagasy</option>
            <option value="ms">Malay</option>
            <option value="dv">Maldivian</option>
            <option value="mt">Maltese</option>
            <option value="gv">Manx Gaelic</option>
            <option value="mi">Maori</option>
            <option value="mh">Marshallese</option>
            <option value="men">Mende</option>
            <option value="mn">Mongolian</option>
            <option value="mfe">Morisyen</option>
            <option value="ne">Nepali</option>
            <option value="niu">Niuean</option>
            <option value="no">Norwegian</option>
            <option value="ny">Nyanja</option>
            <option value="ur">Pakistani</option>
            <option value="pau">Palauan</option>
            <option value="pa">Panjabi</option>
            <option value="pap">Papiamentu</option>
            <option value="ps">Pashto</option>
            <option value="fa">Persian</option>
            <option value="pis">Pijin</option>
            <option value="pl">Polish</option>
            <option value="pt">Portuguese</option>
            <option value="pot">Potawatomi</option>
            <option value="qu">Quechua</option>
            <option value="ro">Romanian</option>
            <option value="ru">Russian</option>
            <option value="sm">Samoan</option>
            <option value="sg">Sango</option>
            <option value="gd">Scots Gaelic</option>
            <option value="sr">Serbian</option>
            <option value="sn">Shona</option>
            <option value="si">Sinhala</option>
            <option value="sk">Slovak</option>
            <option value="sl">Slovenian</option>
            <option value="so">Somali</option>
            <option value="st">Sotho, Southern</option>
            <option value="es">Spanish</option>
            <option value="srn">Sranan Tongo</option>
            <option value="sw">Swahili</option>
            <option value="sv">Swedish</option>
            <option value="de">Swiss German</option>
            <option value="syc">Syriac (Aramaic)</option>
            <option value="tl">Tagalog</option>
            <option value="tg">Tajik</option>
            <option value="tmh">Tamashek (Tuareg)</option>
            <option value="ta">Tamil</option>
            <option value="te">Telugu</option>
            <option value="tet">Tetum</option>
            <option value="th">Thai</option>
            <option value="bo">Tibetan</option>
            <option value="ti">Tigrinya</option>
            <option value="tpi">Tok Pisin</option>
            <option value="tkl">Tokelauan</option>
            <option value="to">Tongan</option>
            <option value="tn">Tswana</option>
            <option value="tr">Turkish</option>
            <option value="tk">Turkmen</option>
            <option value="tvl">Tuvaluan</option>
            <option value="uk">Ukrainian</option>
            <option value="ppk">Uma</option>
            <option value="uz">Uzbek</option>
            <option value="vi">Vietnamese</option>
            <option value="wls">Wallisian</option>
            <option value="cy">Welsh</option>
            <option value="wo">Wolof</option>
            <option value="xh">Xhosa</option>
            <option value="yi">Yiddish</option>
            <option value="zu-ZA">Zulu</option> 
        </select>
        
    </div>

</form>
  )
}
