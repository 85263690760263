import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuthContext } from '../AuthContext';
import { hideComponents } from '../store';
import FaceDetection from './backend/FaceDetection';

export default function Login() {

    const dispatch = useDispatch();

    useEffect(() => {
        // Hide header and sidebars when this route is active
        dispatch(hideComponents({ showHeader: true, showSidebar: false }));
        
        // If you need to reset visibility when component unmounts
        // return () => {
        //     dispatch(hideComponents({ showHeader: true, showSidebar: true }));
        // };
    }, [dispatch]);


    const { login,authState } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    // Redirect if the user is already logged in
    // useEffect(() => {
    //     if (authState.token) {
    //         navigate('/backend'); // Replace with the route you want to redirect to
    //     }
    // }, [authState.token, navigate]);
  
    const handleLogin = async (e) => {
      e.preventDefault();
  
      try {
        const response = await fetch('/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
  
        const data = await response.json();
        if (response.ok) {
          login(data.token,data.user);
          alert('Login successful');
          if(data.user.role===1){
            window.location.href='/view-clients';
          }else{
            window.location.href='/';
          }
        } else {
          alert(data.message);
        }
      } catch (error) {
        console.error('Error logging in:', error);
        alert('An error occurred while logging in');
      }
    };

    return (
        <div
            className="login-wrap d-flex align-items-center flex-wrap justify-content-center"
        >

          
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 col-lg-7">
                        <img src="vendors/images/login-page-img.png" alt="" />
                    </div>
                    <div className="col-md-6 col-lg-5">
                        <div className="login-box bg-white box-shadow border-radius-10">
                            <div className="login-title">
                                <h2 className="text-center text-primary" style={{color: '#eb8d16'}}>Login To AI Human</h2>
                            </div>
                            <form id="login-form" onSubmit={handleLogin}>
                            
                                <div className="input-group custom">
                                     <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    <div className="input-group-append custom">
                                        <span className="input-group-text"
                                            ><i className="icon-copy dw dw-user1"></i
                                        ></span>
                                    </div>
                                </div>
                                <div className="input-group custom">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    <div className="input-group-append custom">
                                        <span className="input-group-text"
                                            ><i className="dw dw-padlock1"></i
                                        ></span>
                                    </div>
                                </div>
                                <div className="row pb-30">
                                    <div className="col-6">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customCheck1"
                                            />
                                            <label className="custom-control-label" for="customCheck1"
                                                >Remember</label
                                            >
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="forgot-password">
                                            <a href="forgot-password.html">Forgot Password</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="input-group mb-0">
                                        
                                            <button
                                                className="btn btn-primary btn-lg btn-block" style={{backgroundColor: '#eb8d16', borderColor: '#eb8d16'}}
                                                
                                                >Sign In</button
                                            >
                                        </div>
                                    
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  

    );
}
            

