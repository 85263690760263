// store.js
import { configureStore, createSlice } from '@reduxjs/toolkit';

const visibilitySlice = createSlice({
  name: 'visibility',
  initialState: {
    showHeader: false,
    showSidebar: false,
    avtarsesssion: null
  },
  reducers: {
    hideComponents(state, action) {
      // Allow passing an object to selectively hide/show components
      state.showHeader = action.payload.showHeader ? action.payload.showHeader : false;
      state.showSidebar = action.payload.showSidebar ? action.payload.showSidebar : false;
    },
    avtarsesssionfun(state, action) {

      console.log("action",action)
      // Allow passing an object to selectively hide/show components
      state.avtarsesssion = action.payload ? action.payload : null;
      
    }
   
  },
});

export const { hideComponents,avtarsesssionfun } = visibilitySlice.actions;

export const store = configureStore({
  reducer: {
    visibility: visibilitySlice.reducer,
  },
});
