import React from 'react'
import Header from './Header'
import RightSidebar from './RightSidebar'
import Leftbar from './Leftbar'
import AddClientForm from './AddClientForm'

export default function AddClient() {
  return (
    <div>
        {/* <Header />
        <RightSidebar />
        <Leftbar /> */}
        <AddClientForm />
    </div>
  )
}
