import { toast } from 'react-toastify';

const UseToast = () => {
  const showSuccessToast = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 9000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  const showWarningToast = (message) => {
    toast.warn(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  // You can also add other types like info, error, etc.
  const showErrorToast = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  // Dismiss a toast by its ID
  const dismissToast = () => {
    toast.dismiss();
  };

  return {
    showSuccessToast,
    showWarningToast,
    showErrorToast,
    dismissToast
  };
};

export default UseToast;
