import { useState, useEffect } from 'react';

const UseTranslationData = (aiForm, lang) => {
    if (aiForm.translations && aiForm.translations[lang]) {
        return aiForm.translations[lang];
    } else {
        console.warn(`No translation data found for language: ${lang}`);
        return null;
    }
};

export default UseTranslationData;
