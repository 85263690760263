import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';


export default function ViewClientTable() {
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState(''); // State to hold search input
  const [filteredClients, setFilteredClients] = useState([]);

  useEffect(() => {
    // Fetch client data from the backend
    fetch('/auth/users') // Update the URL to your API endpoint
      .then(response => response.json())
      .then(resp => {
        // Filter clients with role_id = 2
        const filteredClients = resp?.users.filter(client => client.role === 2);
        setClients(filteredClients);
        setFilteredClients(filteredClients);
      })
      .catch(error => console.error('Error fetching client data:', error));
  }, []);

  useEffect(() => {
    const result = clients.filter(client => {
      return client.first_name.toLowerCase().includes(search.toLowerCase()) ||
             client.last_name.toLowerCase().includes(search.toLowerCase()) ||
             client.username.toLowerCase().includes(search.toLowerCase()) ||
             client.email.toLowerCase().includes(search.toLowerCase()) ||
             client.client_url.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredClients(result);
  }, [search, clients]);

  const columns = [
    {
      name: 'First Name',
      selector: row => row.first_name,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: row => row.last_name,
      sortable: true,
    },
    {
      name: 'Username',
      selector: row => row.username,
      sortable: true,
    },
    {
      name: 'Email Address',
      selector: row => row.email,
      sortable: true,
    },
    // {
    //   name: 'Client URL',
    //   selector: row => row.client_url,
    //   sortable: true,
    // },
    {
      name: 'Client URL',
      cell: row => ( <Link to={`/${row.client_url}`} target='_blank' style={{ 
        textDecoration: 'underline', 
        color: 'blue',
       
        padding:'10px',
        borderRadius:'8px'
       }}>{row.client_url}</Link>),
      sortable: true,
    },
    {
      name: 'Action',
      cell: row => (
        <div className="dropdown">
          <a className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
            <i className="dw dw-more"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
            <a className="dropdown-item" href={`/add-client/${row?._id}`}><i className="dw dw-eye"></i> View Profile</a>
            <a className="dropdown-item" href={`/view-settings/${row?._id}`}><i className="dw dw-edit2"></i> View Setting</a>
            {/* <a className="dropdown-item" href="#"><i className="dw dw-delete-3"></i> Delete</a> */}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="main-container">
      <div className="pd-ltr-20 xs-pd-20-10">
        <div className="min-height-200px">
          <div className="page-header">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="title">
                  <h4>View Clients</h4>
                </div>
              </div>
             
            </div>
          </div>

          <div className="card-box mb-30">
            <div className="pd-20">
              {/* <h4 className="text-blue h4">Data Table Simple</h4> */}
            </div>
            <div className="pb-20">
              {/* Add a search input */}
              <input
                type="text"
                placeholder="Search Clients"
                value={search}
                onChange={e => setSearch(e.target.value)}
                style={{ marginBottom: '50px', padding: '5px', width: '30%', float:"right" }}
                className='form-control'
              />
              <DataTable
                columns={columns}
                data={filteredClients} // Use filtered data
                pagination
                highlightOnHover
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
