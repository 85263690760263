import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


export default function AddClientForm() {

    const { id } = useParams();  // Get the optional client ID from the route

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    password: '',
    frontend_url: ''
  });

 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    fetch('/auth/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => {
        if (response.status === 400) {
          // For status 400, handle it as a client-side error
          return response.json().then(data => { throw new Error(data.message); });
        }
        if (response.status === 201) {
          // For status 201, handle it as a success
          return response.json();
        }
        // Handle other statuses as needed
      })
      .then(data => {
        // Display success message
        console.log('Success:', data.message);
        alert(data.message);  // Show success alert to the user
      })
      .catch(error => {
        // Display error message
        console.error('Error:', error);
        alert('An error occurred: ' + error.message);
      });
  };
  

  useEffect(() => {
    if (id) {
      
      // Fetch client data for the specific ID
      fetch('/auth/users') // Update the URL to your API endpoint
      .then(response => response.json())
      .then(resp => {
        // Filter clients with role_id = 2
        const filteredClients = resp?.users.filter(client => client._id === id);
        console.log("filteredClients",filteredClients)
        setFormData({
            ...filteredClients[0],
            password: ''  // Ensure password field is empty
          });
        
      })
      .catch(error => console.error('Error fetching client data:', error));
    } else {
      // Handle case where no ID is provided
      console.log('No ID provided. Fetching all clients or displaying a message.');
      // You can fetch all clients or display a default message here
    }
  }, [id]);

  const handleSubmitUpdate = async(e) => {
    e.preventDefault();

    //console.log("form",formData)

    try{
    
    const response = await fetch(`/auth/users/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    const data = await response.json();
        if (response.ok) {
          alert(data.message);
          
        } else {
          alert(data.message);
        }
      } catch (error) {
        console.error('Error logging in:', error);
        alert('An error occurred while logging in');
      }
    
  };
  

  return (
    <div className="main-container">
      <div className="pd-ltr-20 xs-pd-20-10">
        <div className="min-height-200px">
          <div className="page-header">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="title">
                  <h4>Add Client</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-30">
              <div className="card-box height-100-p overflow-hidden">
                <div className="profile-tab height-100-p">
                  <div className="tab height-100-p">
                    <ul className="nav nav-tabs customtab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#setting"
                          role="tab"
                        >
                          Settings
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade height-100-p show active"
                        id="setting"
                        role="tabpanel"
                      >
                        <div className="profile-setting">
                          <form onSubmit={id ? handleSubmitUpdate : handleSubmit}>
                            <div className="weight-500 profile-edit-list row">
                              <div className="form-group col-md-6">
                                <label>First Name</label>
                                <input
                                  className="form-control form-control-lg"
                                  type="text"
                                  name="first_name"
                                  value={formData.first_name}
                                  onChange={handleInputChange}
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <label>Last Name</label>
                                <input
                                  className="form-control form-control-lg"
                                  type="text"
                                  name="last_name"
                                  value={formData.last_name}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="weight-500 profile-edit-list row">
                              <div className="form-group col-md-6">
                                <label>Username</label>
                                <input
                                  className="form-control form-control-lg"
                                  type="text"
                                  name="username"
                                  value={formData.username}
                                  onChange={handleInputChange}
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <label>Email Address</label>
                                <input
                                  className="form-control form-control-lg"
                                  type="email"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="weight-500 profile-edit-list row">
                              <div className="form-group col-md-6">
                                <label>Password</label>
                                <input
                                  className="form-control form-control-lg"
                                  type="password"
                                  name="password"
                                  value={formData.password}
                                  onChange={handleInputChange}
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <label>Enter Client Url:</label>
                                <input
                                  className="form-control form-control-lg"
                                  type="text"
                                  name="client_url"
                                  value={formData.client_url}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="weight-500 profile-edit-list row">
                              <div className="form-group col-md-6">
                                <label>Add Client Pin</label>
                                <input
                                  className="form-control form-control-lg"
                                  type="password"
                                  name="client_pin"
                                  value={formData.client_pin}
                                  onChange={handleInputChange}
                                />
                              </div>

                           
                            </div>

                            <div className="weight-500 profile-edit-list row">
                              <div className="form-group mb-0 pl-2">
                                <input
                                  type="submit"
                                  className="btn btn-primary"
                                  value={id ? 'Update Client' : 'Add New Client'}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
