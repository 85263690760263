import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import ReactSlider from "react-slider";
import $ from 'jquery'; // Import jQuery
import 'ion-rangeslider/css/ion.rangeSlider.css'; // Import ionRangeSlider CSS
import 'ion-rangeslider/js/ion.rangeSlider.min.js'; // Import ionRangeSlider JS

const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      // Check if the script is already present
      const existingScript = document.querySelector(`script[src="${src}"]`);
      if (existingScript) {
        console.log(`Script ${src} already loaded`);
        return resolve();
      }
  
      // Create a new script element if not already loaded
      const script = document.createElement('script');
      script.src = src;
      script.async = false; // Load asynchronously
      script.onload = () => {
        console.log(`Script ${src} loaded successfully`);
        resolve();
      };
      script.onerror = (error) => {
        console.error(`Failed to load script ${src}`, error);
        reject(error);
      };
      document.body.appendChild(script);
    });
  };  

export default function MainContent() {

    const [aiFormData, setAiFormData] = useState({});
    const [translationData, setTranslationData] = useState({});
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('admin_lang_set') || 'en');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const location = useLocation(); // Get the current location object




    


    useEffect(()=>{
       
        const loadAllScripts = async () => {
            try {          
              await loadScript('/corefiles/plugins/jquery-steps/jquery.steps.js');
              await loadScript('/corefiles/plugins/ion-rangeslider/js/ion.rangeSlider.min.js'); 
              await loadScript('/vendors/scripts/range-slider-setting.js');       
              await loadScript('/vendors/scripts/steps-setting.js');             
              await loadScript('/corefiles/plugins/jquery-asColor/dist/jquery-asColor.js');
              await loadScript('/corefiles/plugins/jquery-asGradient/dist/jquery-asGradient.js');

              await loadScript('/corefiles/plugins/jquery-asColorPicker/jquery-asColorPicker.js');
              await loadScript('/vendors/scripts/colorpicker.js'); 
              await loadScript('/aiform.js');    
               
              
            } catch (error) {
              console.error('Failed to load one or more scripts', error);
            }
          };
      
          loadAllScripts();

        

    },[location]); 




    // useEffect(()=>{

    //     console.log("aiFormData",aiFormData)
    //     console.log("translationData",translationData)

    // },[aiFormData,translationData])
    
    
    // useEffect(() => {
    //     async function fetchData() {
    //       try {
    //         const { data } = await axios.get('/auth/aiform/all');
    //         const aiForm = data.aiForms[0]; // Assuming there's only one document
    //         console.log("aiForm",aiForm)

    //         setAiFormData(aiForm);
    //         setTranslationData(getTranslationDataForLanguage(aiForm, selectedLanguage));
    //         // Set the checkbox state based on the fetched data
    //         if (aiForm.live_chat_enable === 'yes') {
    //             setSelectedCheckboxes((prev) => [...prev, 'yes']);
    //         } else {
    //             setSelectedCheckboxes((prev) => prev.filter((v) => v !== 'yes'));
    //         }
    
    //         toggleLiveChatElements(aiForm.live_chat_enable === 'yes');

    
    //         // Populate the Select2 dropdown with the selected language
    //         const selectMainLanguage = document.getElementById('main_language');
    //         let options = selectMainLanguage.options;
    
    //         for (let i = 0; i < options.length; i++) {
    //           if (options[i].value === selectedLanguage) {
    //             selectMainLanguage.selectedIndex = i;
    //             break;
    //           }
    //         }
    
    //         // Trigger change to update Select2
    //         selectMainLanguage.dispatchEvent(new Event('change'));
    
    //         // Load images
    //         await fetchImages(selectedLanguage);
    //       } catch (error) {
    //         console.error('Error fetching AI form data:', error);
    //       }
    //     }
    
    //     fetchData();
    //   }, [selectedLanguage]);
    
    //   const getTranslationDataForLanguage = (aiForm, lang) => {
    //     if (aiForm.translations && aiForm.translations[lang]) {
    //       return aiForm.translations[lang];
    //     } else {
    //       console.warn(`No translation data found for language: ${lang}`);
    //       return {};
    //     }
    //   };
    
    //   const fetchImages = async (language) => {
    //     try {
    //       const imgResponse = await axios.get(`/image/${language}`);
    //       document.getElementById('imagee').src = imgResponse.data.img;
    
    //       const avtarPageResponse = await axios.get(`/avtarpageimage/${language}`);
    //       document.getElementById('avtar_page_image_src').src = avtarPageResponse.data.img;
    //     } catch (error) {
    //       console.error('Error fetching images:', error);
    //     }
    //   };
    
    //   const handleFileChange = async (event, uploadUrl, imgElementId) => {
    //     const formData = new FormData();
    //     formData.append('image', event.target.files[0]);
    //     formData.append('name', 'uploaded_image');
    //     formData.append('language', selectedLanguage);
    
    //     try {
    //       const response = await axios.post(uploadUrl, formData);
    //       document.getElementById(imgElementId).src = response.data.img;
    //     } catch (error) {
    //       console.error('Error uploading image:', error);
    //     }
    //   };
    
    //   const handleLiveChatToggle = (event) => {
    //   alert("Sdfds")
    //     // setAiFormData(prevState => ({
    //     //     ...prevState,
    //     //     live_chat_enable: event.target.checked ? 'yes' : 'no'
    //     // }));
    // };
    
    //   const toggleLiveChatElements = (isVisible) => {
    //     const liveChatElements = document.querySelectorAll('.hide-chat');
    //     liveChatElements.forEach((element) => {
    //       element.style.display = isVisible ? 'block' : 'none';
    //     });
    //   };

    const [value, setValue] = useState(50);

    const handleSliderChange = (value) => {
      setValue(value);
    };
    

  return (
    <div className="main-container">
      
    <div className="pd-ltr-20 xs-pd-20-10">
        <div className="min-height-200px">            

            <div className="pd-20 card-box mb-30">
                <div className="clearfix">
                    <h4 className="text-blue h4">AI Setting</h4>
                </div>
                <div className="wizard-content">
                    <form className="tab-wizard wizard-circle wizard vertical">
                        <h5>API Keys</h5>
                        <section>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>HeyGen API Key :</label>
                                        <input 
                                        type="text" 
                                        className="form-control"
                                        id="heygen_api_key"
                                        // value={aiFormData.heygen_api_key || ''} 
                                        // onChange={(e) => setAiFormData({ ...aiFormData, heygen_api_key: e.target.value })}                                        
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Openai Api key :</label>
                                        <input
                                         type="text"
                                         className="form-control" 
                                         name="open_api_key" 
                                         id="open_api_key"
                                        //  value={aiFormData.open_api_key || ''} 
                                        //  onChange={(e) => setAiFormData({ ...aiFormData, open_api_key: e.target.value })} 
                                         
                                         />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Avatar Key Id :</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        name="avtar_key" 
                                        id="avtar_key"
                                        // value={aiFormData.avtar_key || ''} 
                                        // onChange={(e) => setAiFormData({ ...aiFormData, avtar_key: e.target.value })}  
                                        
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Voice Key Id:</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        name="voice_key" 
                                        id="voice_key" 
                                        // value={aiFormData.voice_key || ''} 
                                        // onChange={(e) => setAiFormData({ ...aiFormData, voice_key: e.target.value })} 
                                        
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Avatar Voice Rate should be in range [0.5, 1.5]:</label>
                                        <input 
                                        type="number" 
                                        className="form-control" 
                                        name="voice_rate" 
                                        id="voice_rate" 
                                        // value={aiFormData.voice_rate || ''} 
                                        // onChange={(e) => setAiFormData({ ...aiFormData, voice_rate: e.target.value })} 
                                        
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Avatar Emotion:</label>
                                        <select 
                                        id="avtar_emotion" 
                                        name="avtar_emotion" 
                                        className="form-control"
                                        // value={aiFormData.avtar_emotion || ''}
                                        // onChange={(e) => {
                                        //   const newEmotion = e.target.value;
                                        //   console.log('Selected Emotion:', newEmotion); // Debug log
                                        //   setAiFormData((prevData) => ({
                                        //     ...prevData,
                                        //     avtar_emotion: newEmotion,
                                        //   }));
                                        //   console.log('Updated aiFormData:', aiFormData); // Debug log
                                        // }}

                                        >
                                            <option value="">Select Emotion</option>
                                            <option value="Excited">Excited</option>
                                            <option value="Serious">Serious</option>
                                            <option value="Friendly">Friendly</option>
                                            <option value="Soothing">Soothing</option>
                                            <option value="Broadcaster">Broadcaster</option>

                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>ChatGPT Seeding:</label>
                                        <textarea 
                                        id="modify_response_text" 
                                        className="form-control" 
                                        name="modify_response_text" 
                                        rows="4" 
                                        cols="50"
                                        // value={translationData.modify_response_text || ''} 
                                        // onChange={(e) => setTranslationData({ ...translationData, modify_response_text: e.target.value })}
                                        >
                                            </textarea>
                                    </div>
                                </div>                                  

                            </div>

                            <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="before_response_text">Before Response Text sepreated by |</label>
                                        <textarea 
                                        id="before_response_text" 
                                        className="form-control" 
                                        name="before_response_text" 
                                        rows="4" 
                                        cols="50"
                                        // value={translationData.before_response_text || ''} 
                                        // onChange={(e) => setTranslationData({ ...translationData, before_response_text: e.target.value })}
                                        >
                                        </textarea>

                                    </div>
                                </div>

                            </div>


                            <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="user_not_speak_text">Enter a text if user not speak sepreated by |</label>
                                        <textarea 
                                        id="user_not_speak_text" 
                                        className="form-control" 
                                        name="user_not_speak_text" 
                                        rows="4" 
                                        cols="50"
                                        // value={translationData.before_response_text || ''} 
                                        // onChange={(e) => setTranslationData({ ...translationData, before_response_text: e.target.value })}
                                        >
                                        </textarea>

                                    </div>
                                </div>

                            </div>


                            <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="google_speech_error">Enter a text if Google Speech API create a error |</label>
                                        <textarea 
                                        id="google_speech_error" 
                                        className="form-control" 
                                        name="google_speech_error" 
                                        rows="4" 
                                        cols="50"
                                        // value={translationData.before_response_text || ''} 
                                        // onChange={(e) => setTranslationData({ ...translationData, before_response_text: e.target.value })}
                                        >
                                        </textarea>

                                    </div>
                                </div>

                            </div>


                             <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="text_replace">Enter a replacement text sepreated by L.H.S | R.H.S .Press Enter after each line for new text </label>
                                        <textarea 
                                        id="text_replace" 
                                        className="form-control" 
                                        name="text_replace" 
                                        rows="4" 
                                        cols="50"
                                       
                                        >
                                        </textarea>

                                    </div>
                                </div>

                            </div>


                             <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="user_mic_random_message">Enter a text to play when user mic detect speak text less than or equal 2 word limit. sepreated by | </label>
                                        <textarea 
                                        id="user_mic_random_message" 
                                        className="form-control" 
                                        name="user_mic_random_message" 
                                        rows="4" 
                                        cols="50"
                                       
                                        >
                                        </textarea>

                                    </div>
                                </div>

                            </div>



                            
                        </section>
                        {/* <!-- Step 2 --> */}
                        <h5>Avtar button settings</h5>
                        <section>
                            <div className="row">
                                <div style={{display: 'none' }}>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>AWS Access Key :</label>
                                            <input type="text" className="form-control" name="aws_access_key" id="aws_access_key" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>AWS Secret Key :</label>
                                            <input type="text" className="form-control" name="aws_secret_key" id="aws_secret_key" />
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Home Screen button text 1:</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        name="home_screen_btn_text1" 
                                        id="home_screen_btn_text1"
                                        // value={translationData.home_screen_btn_text1 || ''} 
                                        // onChange={(e) => setTranslationData({ ...translationData, home_screen_btn_text1: e.target.value })}
                                        
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="ask_your_question">Ask Your Question text:</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        name="ask_your_question" 
                                        id="ask_your_question"
                                        // value={translationData.ask_your_question || ''} 
                                        // onChange={(e) => setTranslationData({ ...translationData, ask_your_question: e.target.value })} 
                                        
                                        />
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="listening_text">Listening Text:</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        id="listening_text" 
                                        name="listening_text"                                
                                        
                                        />

                                    </div>
                                </div>

                                </div>

                                <div className='row'>

                                
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="live_chat_enable">Live Chat:</label>
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="live_chat_enable" 
                                        name="live_chat_enable" 
                                        
                                        //defaultChecked={aiFormData.live_chat_enable==='yes'}
                                        //checked={aiFormData.live_chat_enable==='yes'}
                                      
                                        value={"yes"}
                                        

                                        
                                        />

                                    </div>
                                </div>

                                <div className="col-md-12 hide-chat">
                                    <div className="form-group">
                                        <label for="open_chat_new_tab">Open chat window in tab?:</label>
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="open_chat_new_tab" 
                                        name="open_chat_new_tab" 
                                        value="yes"
                                        // defaultChecked={aiFormData.open_chat_new_tab === 'yes' ? true : false}
                                        // onChange={(e) => setAiFormData({ ...aiFormData, open_chat_new_tab: e.target.value })} 
                                        
                                        />
                                    </div>
                                </div>
                                

                                    <div className="col-md-6 hide-chat">
                                        <div className="form-group">
                                            <label>Live chat button text:</label>
                                            <input 
                                            type="text" 
                                            className="form-control" 
                                            name="live_chat_button_text" 
                                            id="live_chat_button_text" 
                                            // value={translationData.live_chat_button_text || ''} 
                                            // onChange={(e) => setTranslationData({ ...translationData, live_chat_button_text: e.target.value })} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 hide-chat">
                                        <div className="form-group">
                                            <label>Live Chat Url:</label>
                                            <input 
                                            type="text" 
                                            className="form-control" 
                                            id="live_chat_url" 
                                            name="live_chat_url"
                                            // value={translationData.live_chat_url || ''} 
                                            // onChange={(e) => setTranslationData({ ...translationData, live_chat_url: e.target.value })} 
                                            
                                            />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="footer_text">Avtar Footer Text:</label>
                                            <input 
                                            type="text" 
                                            className="form-control" 
                                            id="footer_text" 
                                            name="footer_text"
                                            // value={translationData.footer_text || ''} 
                                            // onChange={(e) => setTranslationData({ ...translationData, footer_text: e.target.value })} 
                                            
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="blank_space">How many seconds system wait for blank space before it stops recording:</label>
                                            <input 
                                            type="number" 
                                            className="form-control" 
                                            id="blank_space" 
                                            name="blank_space" 
                                            // value={aiFormData.blank_space || ''} 
                                            // onChange={(e) => setAiFormData({ ...aiFormData, blank_space: e.target.value })} 
                                            
                                            />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="speech_time_limit">Enter a <b>seconds</b> limit so that no matter what happens the recording stops:</label>
                                            <input 
                                            type="number" 
                                            className="form-control" 
                                            id="speech_time_limit" 
                                            name="speech_time_limit"
                                            // value={aiFormData.speech_time_limit || ''} 
                                            // onChange={(e) => setAiFormData({ ...aiFormData, speech_time_limit: e.target.value })} 
                                            
                                            />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="avtar_welcome_text">Avtar Welcome Text:</label>
                                            <textarea 
                                            id="avtar_welcome_text" 
                                            className="form-control" 
                                            name="avtar_welcome_text" 
                                            rows="4" 
                                            cols="50"
                                            // value={translationData.avtar_welcome_text || ''} 
                                            // onChange={(e) => setTranslationData({ ...translationData, avtar_welcome_text: e.target.value })} 
                                            
                                            >
                                            </textarea>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Avtar Footer Text Color
                                            </label>
                                            <input
                                                type="text"
                                                className="complex-colorpicker form-control welcom_text_color asColorPicker-input"
                                                
                                                id="welcom_text_color" name="welcom_text_color"
                                                // value={translationData.welcom_text_color || ''} 
                                                // onChange={(e) => setTranslationData({ ...translationData, welcom_text_color: e.target.value })} 
                                            />
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Avtar Footer Text Background Color
                                            </label>
                                            <input
                                                type="text"
                                                className="complex-colorpicker form-control asColorPicker-input welcom_text_back_color"
                                                
                                                id="welcom_text_back_color" name="welcom_text_back_color"
                                                // value={translationData.welcom_text_back_color || ''} 
                                                // onChange={(e) => setTranslationData({ ...translationData, welcom_text_back_color: e.target.value })} 
                                            />
                                            

                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="promo_time_limit">Enter a time in <b>minutes</b> to show a promo message:</label>
                                            <input 
                                            type="number" 
                                            className="form-control" 
                                            id="promo_time_limit" 
                                            name="promo_time_limit"
                                            // value={aiFormData.promo_time_limit || ''} 
                                            // onChange={(e) => setAiFormData({ ...aiFormData, promo_time_limit: e.target.value })}
                                            
                                            />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="promo_message">Enter a Promo messages sepreated by |</label>
                                            <textarea 
                                            id="promo_message" 
                                            className="form-control" 
                                            name="promo_message" 
                                            rows="4" 
                                            cols="50"
                                            // value={translationData.promo_message || ''} 
                                            // onChange={(e) => setTranslationData({ ...translationData, promo_message: e.target.value })} 
                                            
                                            >
                                            </textarea>

                                        </div>
                                    </div>          
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                <div className="form-group">
                                    <label for="select_target_mm">Select Language:</label>
                                    <select id="select_target_mm" name="tl" multiple="multiple" className="form-control" style={{width: '50%'}}>
                                        <option value="af">Afrikaans</option>
                                        <option value="sq">Albanian</option>
                                        <option value="am">Amharic</option>
                                        <option value="ar">Arabic</option>
                                        <option value="hy">Armenian</option>
                                        <option value="az">Azerbaijani</option>
                                        <option value="bjs">Bajan</option>
                                        <option value="rm">Balkan Gipsy</option>
                                        <option value="eu">Basque</option>
                                        <option value="bem">Bemba</option>
                                        <option value="bn">Bengali</option>
                                        <option value="be">Bielarus</option>
                                        <option value="bi">Bislama</option>
                                        <option value="bs">Bosnian</option>
                                        <option value="br">Breton</option>
                                        <option value="bg">Bulgarian</option>
                                        <option value="my">Burmese</option>
                                        <option value="ca">Catalan</option>
                                        <option value="ceb">Cebuano</option>
                                        <option value="ch">Chamorro</option>
                                        <option value="zh">Chinese (Simplified)</option>
                                        <option value="zh">Chinese Traditional</option>
                                        <option value="zdj">Comorian (Ngazidja)</option>
                                        <option value="cop">Coptic</option>
                                        <option value="aig">Creole English (Antigua and Barbuda)</option>
                                        <option value="bah">Creole English (Bahamas)</option>
                                        <option value="gcl">Creole English (Grenadian)</option>
                                        <option value="gyn">Creole English (Guyanese)</option>
                                        <option value="jam">Creole English (Jamaican)</option>
                                        <option value="svc">Creole English (Vincentian)</option>
                                        <option value="vic">Creole English (Virgin Islands)</option>
                                        <option value="ht">Creole French (Haitian)</option>
                                        <option value="acf">Creole French (Saint Lucian)</option>
                                        <option value="crs">Creole French (Seselwa)</option>
                                        <option value="pov">Creole Portuguese (Upper Guinea)</option>
                                        <option value="hr">Croatian</option>
                                        <option value="cs">Czech</option>
                                        <option value="da">Danish</option>
                                        <option value="nl">Dutch</option>
                                        <option value="dz">Dzongkha</option>
                                        <option value="en">English</option>
                                        <option value="eo">Esperanto</option>
                                        <option value="et">Estonian</option>
                                        <option value="fn">Fanagalo</option>
                                        <option value="fo">Faroese</option>
                                        <option value="fi">Finnish</option>
                                        <option value="fr">French</option>
                                        <option value="gl">Galician</option>
                                        <option value="ka">Georgian</option>
                                        <option value="de">German</option>
                                        <option value="el">Greek</option>
                                        <option value="grc">Greek (classNameical)</option>
                                        <option value="gu">Gujarati</option>
                                        <option value="ha">Hausa</option>
                                        <option value="haw">Hawaiian</option>
                                        <option value="he">Hebrew</option>
                                        <option value="hi">Hindi</option>
                                        <option value="hu">Hungarian</option>
                                        <option value="is">Icelandic</option>
                                        <option value="id">Indonesian</option>
                                        <option value="kl">Inuktitut (Greenlandic)</option>
                                        <option value="ga">Irish Gaelic</option>
                                        <option value="it">Italian</option>
                                        <option value="ja">Japanese</option>
                                        <option value="jv">Javanese</option>
                                        <option value="kea">Kabuverdianu</option>
                                        <option value="kab">Kabylian</option>
                                        <option value="kn">Kannada</option>
                                        <option value="kk">Kazakh</option>
                                        <option value="km">Khmer</option>
                                        <option value="rw">Kinyarwanda</option>
                                        <option value="rn">Kirundi</option>
                                        <option value="ko">Korean</option>
                                        <option value="ku">Kurdish</option>
                                        <option value="ckb">Kurdish Sorani</option>
                                        <option value="ky">Kyrgyz</option>
                                        <option value="lo">Lao</option>
                                        <option value="la">Latin</option>
                                        <option value="lv">Latvian</option>
                                        <option value="lt">Lithuanian</option>
                                        <option value="lb">Luxembourgish</option>
                                        <option value="mk">Macedonian</option>
                                        <option value="mg">Malagasy</option>
                                        <option value="ms">Malay</option>
                                        <option value="dv">Maldivian</option>
                                        <option value="mt">Maltese</option>
                                        <option value="gv">Manx Gaelic</option>
                                        <option value="mi">Maori</option>
                                        <option value="mh">Marshallese</option>
                                        <option value="men">Mende</option>
                                        <option value="mn">Mongolian</option>
                                        <option value="mfe">Morisyen</option>
                                        <option value="ne">Nepali</option>
                                        <option value="niu">Niuean</option>
                                        <option value="no">Norwegian</option>
                                        <option value="ny">Nyanja</option>
                                        <option value="ur">Pakistani</option>
                                        <option value="pau">Palauan</option>
                                        <option value="pa">Panjabi</option>
                                        <option value="pap">Papiamentu</option>
                                        <option value="ps">Pashto</option>
                                        <option value="fa">Persian</option>
                                        <option value="pis">Pijin</option>
                                        <option value="pl">Polish</option>
                                        <option value="pt">Portuguese</option>
                                        <option value="pot">Potawatomi</option>
                                        <option value="qu">Quechua</option>
                                        <option value="ro">Romanian</option>
                                        <option value="ru">Russian</option>
                                        <option value="sm">Samoan</option>
                                        <option value="sg">Sango</option>
                                        <option value="gd">Scots Gaelic</option>
                                        <option value="sr">Serbian</option>
                                        <option value="sn">Shona</option>
                                        <option value="si">Sinhala</option>
                                        <option value="sk">Slovak</option>
                                        <option value="sl">Slovenian</option>
                                        <option value="so">Somali</option>
                                        <option value="st">Sotho, Southern</option>
                                        <option value="es">Spanish</option>
                                        <option value="srn">Sranan Tongo</option>
                                        <option value="sw">Swahili</option>
                                        <option value="sv">Swedish</option>
                                        <option value="de">Swiss German</option>
                                        <option value="syc">Syriac (Aramaic)</option>
                                        <option value="tl">Tagalog</option>
                                        <option value="tg">Tajik</option>
                                        <option value="tmh">Tamashek (Tuareg)</option>
                                        <option value="ta">Tamil</option>
                                        <option value="te">Telugu</option>
                                        <option value="tet">Tetum</option>
                                        <option value="th">Thai</option>
                                        <option value="bo">Tibetan</option>
                                        <option value="ti">Tigrinya</option>
                                        <option value="tpi">Tok Pisin</option>
                                        <option value="tkl">Tokelauan</option>
                                        <option value="to">Tongan</option>
                                        <option value="tn">Tswana</option>
                                        <option value="tr">Turkish</option>
                                        <option value="tk">Turkmen</option>
                                        <option value="tvl">Tuvaluan</option>
                                        <option value="uk">Ukrainian</option>
                                        <option value="ppk">Uma</option>
                                        <option value="uz">Uzbek</option>
                                        <option value="vi">Vietnamese</option>
                                        <option value="wls">Wallisian</option>
                                        <option value="cy">Welsh</option>
                                        <option value="wo">Wolof</option>
                                        <option value="xh">Xhosa</option>
                                        <option value="yi">Yiddish</option>
                                        <option value="zu-ZA">Zulu</option> 
                                    </select>
                                    
                                </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="speech_text_response">Enable Speech Text Response? (Desktop):
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="speech_text_response" 
                                        name="speech_text_response" 
                                        value="yes" 
                                        // checked={aiFormData.speech_text_response === 'yes'}
                                        // onChange={(e) => setAiFormData({ ...aiFormData, speech_text_response: e.target.value })} 
                                        
                                        />
                                        </label>

                                    </div>
                                </div>
                            </div>
                            
                            <div className='row' style={{backgroundColor:"antiquewhite", padding:"10px", marginTop:"3px"}}>

                            <div className='col-md-12'>
                                    <div className="form-group">
                                        <label for="enable_speech_btn">Enable Speech Button?
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="enable_speech_btn" 
                                        name="enable_speech_btn" 
                                        value="yes" 
                                        // checked={aiFormData.speech_text_response === 'yes'}
                                        // onChange={(e) => setAiFormData({ ...aiFormData, speech_text_response: e.target.value })} 
                                        
                                        />
                                        </label>

                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label for="enable_face_detection">Enable FaceDetection?
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="enable_face_detection" 
                                        name="enable_face_detection" 
                                        value="yes" 
                                        // checked={aiFormData.speech_text_response === 'yes'}
                                        // onChange={(e) => setAiFormData({ ...aiFormData, speech_text_response: e.target.value })} 
                                        
                                        />
                                        </label>

                                    </div>
                                </div>

                                <div className='col-md-12 hide_facedetection'>
                                    <div className="form-group">
                                        <label for="disable_camera_front">Hide Camera From Front?
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="disable_camera_front" 
                                        name="disable_camera_front" 
                                        value="yes" 
                                        // checked={aiFormData.speech_text_response === 'yes'}
                                        // onChange={(e) => setAiFormData({ ...aiFormData, speech_text_response: e.target.value })} 
                                        
                                        />
                                        </label>

                                    </div>
                                </div>

                                <div className='col-md-4 hide_facedetection'>
                                    <div className='form-group'>                
                                    <input id="fecedetection_time_range"  />                                 
                                    </div>
                                </div>

                              

                                
                                <div className="col-md-4 hide_facedetection">
                                    <span><strong>Face Detection Camera Position</strong></span>
                                    <div className="form-group"> 
                                    <label className="">
                                        <input type="radio" name="face_detection_location" value="top-right" />Top Right
                                    </label>
                                    <label className="">
                                        <input type="radio" name="face_detection_location" value="top-left" />Top Left
                                    </label>
                                    <label className="">
                                        <input type="radio" name="face_detection_location" value="bottom-left" />Bottom Left
                                    </label>
                                    
                                    <label className="">
                                        <input type="radio" name="face_detection_location" value="bottom-right" />Bottom Right
                                    </label>
                                    </div>

                                </div>
                                <div className="col-md-4 hide_facedetection">
                                    <div className="form-group">
                                        <label for="time_rane">Enter Time Range in Second:
                                        <input 
                                        type="number" 
                                        className="" 
                                        id="time_rane" 
                                        name="time_rane" 
                                       
                                        // checked={aiFormData.speech_text_response === 'yes'}
                                        // onChange={(e) => setAiFormData({ ...aiFormData, speech_text_response: e.target.value })} 
                                        
                                        />
                                        </label>

                                    </div>

                                </div>

                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label for="enable_rizwan_event">Enable Rizwan Event?
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="enable_rizwan_event" 
                                        name="enable_rizwan_event" 
                                        value="yes" 
                                        // checked={aiFormData.speech_text_response === 'yes'}
                                        // onChange={(e) => setAiFormData({ ...aiFormData, speech_text_response: e.target.value })} 
                                        
                                        />
                                        </label>

                                    </div>
                                </div>

                                <div className='col-md-12 hide_rizwan_btn'>
                                    <div className="form-group">
                                        <label for="enable_rizwan_btn">Enable Rizwan Event Button?
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="enable_rizwan_btn" 
                                        name="enable_rizwan_btn" 
                                        value="yes" 
                                        // checked={aiFormData.speech_text_response === 'yes'}
                                        // onChange={(e) => setAiFormData({ ...aiFormData, speech_text_response: e.target.value })} 
                                        
                                        />
                                        </label>

                                    </div>
                                </div>

                                <div className='col-md-12 enable_rizwan_btn_child'>
                                    <div className="form-group">
                                        <label for="enable_rizwan_btn_text_1">Enter Rizwan Event Step 1 Button Text
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        id="enable_rizwan_btn_text_1" 
                                        name="enable_rizwan_btn_text_1"                                      
                                      
                                        
                                        />
                                        </label>

                                    </div>
                                </div>

                                <div className='col-md-12 enable_rizwan_btn_child'>
                                    <div className="form-group">
                                        <label for="enable_rizwan_btn_text">Enter Rizwan Event Step 2 Button Text
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        id="enable_rizwan_btn_text" 
                                        name="enable_rizwan_btn_text"                                      
                                      
                                        
                                        />
                                        </label>

                                    </div>
                                </div>

                                <div className='col-md-12 hide_rizwan_btn'>
                                    <div className="form-group">
                                        <label for="enable_rizwan_manual_script">Enable Rizwan Event Manual Script?
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="enable_rizwan_manual_script" 
                                        name="enable_rizwan_manual_script" 
                                        value="yes" 
                                        // checked={aiFormData.speech_text_response === 'yes'}
                                        // onChange={(e) => setAiFormData({ ...aiFormData, speech_text_response: e.target.value })} 
                                        
                                        />
                                        </label>

                                    </div>
                                </div>

                                <div className="col-md-4 hide_rizwan_btn">
                                    <div className="form-group">
                                        <label for="time_rane_splash">Enter Time Range in Second for Splash Screen Reload Button:
                                        <input 
                                        type="number" 
                                        className="" 
                                        id="time_rane_splash" 
                                        name="time_rane_splash"                                       
                                     
                                        />
                                        </label>

                                    </div>

                                </div>


                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label for="enable_python_script">Enable Python Script?
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="enable_python_script" 
                                        name="enable_python_script" 
                                        value="yes"                                     
                                        
                                        />
                                        </label>

                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label for="bypass_login">Bypass the login?
                                        <input 
                                        type="checkbox" 
                                        className="" 
                                        id="bypass_login" 
                                        name="bypass_login" 
                                        value="yes"                                     
                                        
                                        />
                                        </label>

                                    </div>
                                </div>
                               
                            </div>
                        </section>
                        {/* <!-- Step 3 --> */}
                        <h5>Site Settings</h5>
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        {/* <label>Site Brand Color :</label>
                                        <input className="form-control" id="site_brand_color" name="site_brand_color" value="#563d7c" type="color" /> */}

                                        <label>
                                            Buttons color
                                            <div className="font-14">
                                                use <code>.gradient-colorpicker</code> in your input
                                                element.
                                            </div>
                                        </label>
                                        <input
                                            type="text"
                                            className="complex-colorpicker form-control asColorPicker-input site_brand_color"
                                            
                                            id="site_brand_color" name="site_brand_color"
                                            // value={translationData.site_brand_color || ''} 
                                            // onChange={(e) => setTranslationData({ ...translationData, site_brand_color: e.target.value })} 
                                        />
                                    </div>
                                    
                                    
                                </div>                                  
                            </div>


                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="image" className="btn btn-primary">Choose logo</label>
                                    <input type="file" id="image" name="image" accept="image/*" style={{display: 'none'}} required  />
                                    
                                    <div id="response"></div>
                                    <img id="imagee" src="" alt="Uploaded Image" />
                                    </div>


                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="avtar_page_image" className="btn btn-primary">Choose Avtar Page logo</label>
                                    <input type="file" id="avtar_page_image" name="avtar_page_image" accept="image/*" style={{display: 'none'}} required />
                                    
                                    <div id="response"></div>
                                    <img id="avtar_page_image_src" src="" alt="Uploaded Image" />
                                    </div>


                                </div>

                                <div className="col-md-6">
                                    <span><strong>Avtar Logo Position</strong></span>
                                    <div className="form-group"> 
                                    <label className="">
                                        <input type="radio" name="avtar_page_image_location" value="top-right" />Top Right
                                    </label>
                                    <label className="">
                                        <input type="radio" name="avtar_page_image_location" value="top-left" />Top Left
                                    </label>
                                    <label className="">
                                        <input type="radio" name="avtar_page_image_location" value="bottom-left" />Bottom Left
                                    </label>
                                    
                                    <label className="">
                                        <input type="radio" name="avtar_page_image_location" value="bottom-right" />Bottom Right
                                    </label>
                                    </div>

                                </div>

                                
                            </div>


                             <div className='row'>

                                <div className='col-md-12'>
                                <h4><strong>Add Availablity Timings Here:</strong></h4>
                                <div className="form-group"> 
                                <div className="day-slot">
                                        <input type="checkbox" id="monday-open" name="monday-open" checked />
                                        <label for="monday">Monday:</label>
                                        <input type="time" id="monday-start" name="monday-start" /> to
                                        <input type="time" id="monday-end" name="monday-end" />
                                    </div>
                                    <div className="day-slot">
                                        <input type="checkbox" id="tuesday-open" name="tuesday-open" checked />
                                        <label for="tuesday">Tuesday:</label>
                                        <input type="time" id="tuesday-start" name="tuesday-start" /> to
                                        <input type="time" id="tuesday-end" name="tuesday-end" />
                                    </div>
                                    <div className="day-slot">
                                        <input type="checkbox" id="wednesday-open" name="wednesday-open" checked />
                                        <label for="wednesday">Wednesday:</label>
                                        <input type="time" id="wednesday-start" name="wednesday-start" /> to
                                        <input type="time" id="wednesday-end" name="wednesday-end" />
                                    </div>
                                    <div className="day-slot">
                                        <input type="checkbox" id="thursday-open" name="thursday-open" checked />
                                        <label for="thursday">Thursday:</label>
                                        <input type="time" id="thursday-start" name="thursday-start" /> to
                                        <input type="time" id="thursday-end" name="thursday-end" />
                                    </div>
                                    <div className="day-slot">
                                        <input type="checkbox" id="friday-open" name="friday-open" checked />
                                        <label for="friday">Friday:</label>
                                        <input type="time" id="friday-start" name="friday-start" /> to
                                        <input type="time" id="friday-end" name="friday-end" />
                                    </div>
                                    <div className="day-slot">
                                        <input type="checkbox" id="saturday-open" name="saturday-open" checked />
                                        <label for="saturday">Saturday:</label>
                                        <input type="time" id="saturday-start" name="saturday-start" /> to
                                        <input type="time" id="saturday-end" name="saturday-end" />
                                    </div>
                                    <div className="day-slot">
                                        <input type="checkbox" id="sunday-open" name="sunday-open" checked />
                                        <label for="sunday">Sunday:</label>
                                        <input type="time" id="sunday-start" name="sunday-start" /> to
                                        <input type="time" id="sunday-end" name="sunday-end" />
                                    </div>
                                </div>
                                </div>

                            </div>




                            
                        </section>
                      
                        
                    </form>
                </div>
            </div>

       
           
        </div>
        
    </div>

   
</div>
  
  )
}
