import React from 'react';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>403 - Not Authorized</h1>
      <p>You do not have permission to view this page.</p>
      <p>If you believe this is a mistake, please contact your administrator.</p>
      <Link to="/backend" style={{ textDecoration: 'none', color: 'blue' }}>
        Go Back to Home
      </Link>
    </div>
  );
};

export default Unauthorized;
