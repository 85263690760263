import React from 'react'
import Header from './Header'
import RightSidebar from './RightSidebar'
import Leftbar from './Leftbar'
import MainContent from './MainContent'

export default function BackendForm() {
  return (
    <div>
   {/* <Header />
   <RightSidebar />
   <Leftbar /> */}
   <MainContent />
   </div>
  )
}
