import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

export default function ViewPyLogs() {

    const [clients, setClients] = useState([]);


   useEffect(() => {
        fetch('/py-logs')
            .then(response => response.json())
            .then(resp => {
                // const filteredClients = resp?.users;
                // setClients(filteredClients);

                const filteredUsers = resp?.users.filter(user => user.logs && user.logs.length > 0);
               
                setClients(filteredUsers)
                
            })
            .catch(error => console.error('Error fetching client data:', error));
    }, []);

    const LogViewer = ({ data }) => (
        <div>
            <h2>Python Script Log data</h2>
            {data?.map(user => (
                <div key={user._id} className="user-section">
                    <h3>Session ID: {user.session_id}</h3>
                    <div className="logs">
                        {user?.logs?.map(log => (
                            <div key={log._id} className="log-entry">
                                <p><strong>Timestamp:</strong> {new Date(log.timestamp).toLocaleString()}</p>
                                <p><strong>Level:</strong> {log.level}</p>
                                <p><strong>Message:</strong> {log.message}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        return date.toLocaleString('en-US', options).replace(',', '');
    };


    const columns = [

        {
            name: 'Log Time',
            selector: row => formatTimestamp(row.created_at),
            sortable: true,
            width: '200px',
        },
     
     
      {
          name: 'View Log',
          cell: row => (
            <Link to={`/view-py-logdetails/${row._id}`} target='_blank' style={{ 
              textDecoration: 'underline', 
              color: 'blue',
              padding: '10px',
              borderRadius: '8px'
            }}>View Log</Link>
          ),
          sortable: true,
          width: '200px',
      },
   
  ];

  const customStyles = {
      rows: {
          style: {
              marginBottom: '10px',
              padding: '10px 0',
              borderBottom: '1px solid #ccc',
          },
      },
      tableWrapper: {
          style: {
              overflowX: 'auto',
          },
      },
  };



  return (
    <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
                <div className="min-height-200px">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="title">
                                    <h4>View Python LLM Logs</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-box mb-30">
                        <div className="pd-20">
                        </div>
                        <div className="pb-20">
                          
                            <DataTable
                                columns={columns}
                                data={clients} // Use filtered data
                                pagination
                                paginationPerPage={25} // Set default records per page to 25
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
